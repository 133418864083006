import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class Clients extends Component {

  render() {
    const { title, content, clients } = this.props

    return (
      <>
        <section className='clients full-section'>
          <div className='clients__inner'>
            <h2 dangerouslySetInnerHTML={{ __html: title }} />
            <div dangerouslySetInnerHTML={{ __html: content }} />
            <div className='clients__items'>
              { clients.map((el, i) => (
                <div className='clients__item' key={i}>
                  { el.logo && <img src={el.logo?.localFile?.childImageSharp.original.src} alt={title} /> }
                </div>
              )) }
            </div>
          </div>
        </section>
      </>
    )
  }
}

export default Clients
