import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class AdditionalServices extends Component {

  _scrollToContact = () => {
    document && document.querySelector('#contact').scrollIntoView({behavior: 'smooth'})
  }

  render() {
    const { subtitle, title, content, services, lastTile } = this.props

    return (
      <>
        <section className='additional full-section'>
          <div className='additional__inner'>
          <span className='subtitle'>{subtitle}</span>
            <h2 dangerouslySetInnerHTML={{ __html: title }} />
            <div dangerouslySetInnerHTML={{ __html: content }} />
            <div className='additional__items'>
              { services.map((el, i) => (
                <div className='additional__item' key={i}>
                  { el.icon && <img src={el.icon?.localFile?.childImageSharp.original.src} alt={el.label} /> }
                  <h4>{el.label}</h4>
                </div>
              )) }
              <div className='additional__item last' onClick={this._scrollToContact}>
                <div dangerouslySetInnerHTML={{ __html: lastTile }} />
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}

export default AdditionalServices