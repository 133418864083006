import React, { Component } from 'react'
import { graphql } from 'gatsby'
import he from 'he'
import ReactFullpage from '@fullpage/react-fullpage'

import SEO from '../components/seo'
import Footer from '../components/footer'

import RenderBlock from '../utils/render-block'

const Modal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? 'modal active' : 'modal';
  return (
    <div className={showHideClassName}>
      <section className='modal__wrapper'>
        { show && children }
        <button className='modal__close' onClick={handleClose} />
      </section>
    </div>
  )
}

class PageTemplate extends Component {

  state = {
    modal: false,
    content: null,
  }

  componentDidMount() {
    setTimeout(() => {
      if ( window.fullpage_api ) {
        window.fullpage_api.reBuild()
      }
    }, 150)

    //let vh = window.innerHeight * 0.01;
    //document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', () => {
      setTimeout(() => {
        //let vh = window.innerHeight * 0.01;
        //document.documentElement.style.setProperty('--vh', `${vh}px`);
        window.fullpage_api && window.fullpage_api.reBuild()
      }, 150)
    })
  }

  toggleModal = () => {
    let modal = !this.state.modal;
    this.setState({ modal })
    document.querySelector('body').classList.toggle('menu-open', modal)
  }

  setModal = (content, modal = true) => {
    this.setState({ modal, content })
    document.querySelector('body').classList.toggle('menu-open', modal)
  }

  onLeave = (origin, dest, dir) => {

    if ( origin.index >= 0 ) {
      document.querySelector('.header').classList.add('header--white')
      document.querySelector('.header').classList.add('header--scrolled')
    }

    if ( dest.index === 0) {
      document.querySelector('.header').classList.remove('header--scrolled')
    }

  }

  render() {
    let page = this.props.data.wpPage

    let content = (
      <>
        { page.acf.contentBlocks && page.acf.contentBlocks.map((el, i) => RenderBlock(el.fieldGroupName, el, i, this.setModal))  }
        <Footer />
      </>
    )

    return (
      <>
        <SEO
          title={he.decode(page.seo.title)}
          description={page.seo.metaDesc || page.seo.opengraphDescription}
          bodyClass={page.slug}
          image={page.seo.opengraphImage && page.seo.opengraphImage.localFile.childImageSharp.original}
          og={page.seo}
        />
        { content }
        <Modal className='modal' show={this.state.modal} handleClose={this.toggleModal}>
          { this.state.content }
        </Modal>
      </>
    )
  }
}

export const pageQuery = graphql`
  query ($id: String!) {
    wpPage(id: {eq: $id}) {
      title
      slug
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          sourceUrl
          altText
          localFile {
            childImageSharp {
              original {
                src
                width
                height
              }
            }
          }
        }
      }
      acf {
        contentBlocks {
          __typename
          ... on WpPage_Acf_ContentBlocks_ContactForm {
            fieldGroupName
            heading
          }
          ... on WpPage_Acf_ContentBlocks_ProjectSlider {
            fieldGroupName
            heading
            slides {
              info
              title
              videoUrl
              picture {
                sourceUrl
                altText
                localFile {
                  childImageSharp {
                    original {
                      src
                      width
                      height
                    }
                  }
                }
              }
            }
          }
          ... on WpPage_Acf_ContentBlocks_ProjectGallery {
            fieldGroupName
            heading
            items {
              title
              videoUrl
              picture {
                sourceUrl
                altText
                localFile {
                  childImageSharp {
                    original {
                      src
                      width
                      height
                    }
                  }
                }
              }
              info
            }
          }
          ... on WpPage_Acf_ContentBlocks_ContactMap {
            fieldGroupName
            googleMap {
              latitude
              longitude
            }
            heading
            subheading
            content
          }
          ... on WpPage_Acf_ContentBlocks_VideoBanner {
            fieldGroupName
            title
            content
            video
            mobileVideo
            poster {
              sourceUrl
              altText
              localFile {
                childImageSharp {
                  original {
                    src
                    width
                    height
                  }
                }
              }
            }
          }
          ... on WpPage_Acf_ContentBlocks_Banner {
            fieldGroupName
            bannerImage {
              sourceUrl
              altText
              localFile {
                childImageSharp {
                  original {
                    src
                    width
                    height
                  }
                }
              }
            }
          }
          ... on WpPage_Acf_ContentBlocks_Clients {
            fieldGroupName
            title
            content
            clients {
              logo {
                altText
                localFile {
                  childImageSharp {
                    original {
                      src
                      width
                      height
                    }
                  }
                }
              }
            }
          }
          ... on WpPage_Acf_ContentBlocks_Process {
            fieldGroupName
            subtitle
            title
            content
            process {
              icon {
                localFile {
                  childImageSharp {
                    original {
                      src
                      width
                      height
                    }
                  }
                }
              }
              label
              content
            }
          }
          ... on WpPage_Acf_ContentBlocks_AdditionalServices {
            fieldGroupName
            subtitle
            title
            content
            services {
              icon {
                localFile {
                  childImageSharp {
                    original {
                      src
                      width
                      height
                    }
                  }
                }
              }
              label
            }
            lastTile
          }
          ... on WpPage_Acf_ContentBlocks_PagesRepeater {
            fieldGroupName
            repeaterItem {
              tagline
              title
              content
              buttonText
              buttonLink
              picture {
                sourceUrl
                altText
                localFile {
                  childImageSharp {
                    original {
                      src
                      width
                      height
                    }
                  }
                }
              }
              video
              mobileVideo
            }
          }
          ... on WpPage_Acf_ContentBlocks_PagesRepeaterSlider {
            fieldGroupName
            repeaterItem {
              tagline
              title
              content
              buttonText
              buttonLink
              slides {
                picture {
                  sourceUrl
                  altText
                  localFile {
                    childImageSharp {
                      original {
                        src
                        width
                        height
                      }
                    }
                  }
                }
              }
            }
          }
          ... on WpPage_Acf_ContentBlocks_PagesRepeaterDouble {
            fieldGroupName
            repeaterItem {
              tagline
              pictureBlock {
                sourceUrl
                altText
                localFile {
                  childImageSharp {
                    original {
                      src
                      width
                      height
                    }
                  }
                }
              }
              video
              mobileVideo
              detailsRepeater {
                title
                content
                buttonText
                buttonLink
              }
            }
          }
          ... on WpPage_Acf_ContentBlocks_BannerTitle {
            fieldGroupName
            heading
            subheading
            video
            mobileVideo
            banner {
              sourceUrl
              altText
              localFile {
                childImageSharp {
                  original {
                    src
                    width
                    height
                  }
                }
              }
            }
          }
          ... on WpPage_Acf_ContentBlocks_Features {
            fieldGroupName
            information
            buttonText
            featuresTitle
            pdfDownload {
              link
            }
            features {
              picture {
                sourceUrl
                altText
                localFile {
                  childImageSharp {
                    original {
                      src
                      width
                      height
                    }
                  }
                }
              }
              information
            }
          }
          ... on WpPage_Acf_ContentBlocks_Studio {
            fieldGroupName
            gallery {
              altText
              localFile {
                childImageSharp {
                  original {
                    src
                    width
                    height
                  }
                }
              }
            }
            information
            buttonText
            featuresTitle
            pdfDownload {
              link
            }
            features {
              picture {
                sourceUrl
                altText
                localFile {
                  childImageSharp {
                    original {
                      src
                      width
                      height
                    }
                  }
                }
              }
              information
            }
          }
          ... on WpPage_Acf_ContentBlocks_ImageContent {
            fieldGroupName
            picture {
              sourceUrl
              altText
              localFile {
                childImageSharp {
                  original {
                    src
                    width
                    height
                  }
                }
              }
            }
            heading
            content
          }
          ... on WpPage_Acf_ContentBlocks_VideoContent {
            fieldGroupName
            title
            content
            video
          }
        }
      }
    }
  }
`

export default PageTemplate
