import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class Studio extends Component {

  state = {
    activeSlide: 0,
  }

  changeSlide(i) {
    this.setState({ activeSlide: i })
  }

  renderLink = () => {
    const { pdfDownload, buttonText } = this.props

    let props = {
      className: "btn btn--fancy",
      target: pdfDownload ? '_blank': '',
      href: pdfDownload ? pdfDownload.link : '#contact',
      onClick: (e) => {
        if (!pdfDownload && window.fullpage_api) {

          if (this.props.location.pathname === '/' || this.props.location.pathname.indexOf('/studios') !== -1) {

            e.preventDefault()
            let target = document.querySelector('.contact-form').closest('.full-section')

            let list = document.querySelectorAll('.full-section')
            for (let i = 0, l = list.length; i++ < l;) {
              if (list[i] === target)
                window.fullpage_api.moveTo(i + 1)
            }
          }

        }
      }
    }

    return (
      <a {...props}>
        <span>{buttonText}</span>
      </a>
    )
  }


  render() {
    const { title, gallery, information, featuresTitle, features } = this.props
    let { activeSlide } = this.state

    return (
      <section className="features features--studio">
        <div className="features__inner">
          <div className='gallery'>
            <div className='gallery__main'>
              <img src={gallery[activeSlide]?.localFile?.childImageSharp?.original?.src} alt={gallery[activeSlide]?.altText} />
              <h4>{title}</h4>
            </div>
            <div className='gallery__items'>
              { gallery.map((el, i) => <img src={el.localFile?.childImageSharp?.original?.src} alt={el.altText} key={i} onClick={() => this.changeSlide(i)} />) }
            </div>
          </div>
          <div className="content">
            <Fade>
              <div dangerouslySetInnerHTML={{__html: information}} />
              { this.renderLink() }
              {/* <a className="btn btn--fancy" href={'#contact'}><span>{buttonText}</span></a> */}
            </Fade>
          </div>
          <div className="features-list">
            <Fade>
              <h5>{featuresTitle}</h5>
              <ul>
              { features && features.map((el, i) => {
                let props = {
                  className: `features-item`,
                  ref: `q_${i}`,
                  key: i,
                }
                return (
                  <li {...props}>
                    <img src={el.picture?.localFile?.childImageSharp?.original?.src} alt={el.picture.altText} />
                    <p>{el.information}</p>
                  </li>
                )
                })}
              </ul>
            </Fade>
          </div>
        </div>
      </section>
    )
  }
}

export default Studio
