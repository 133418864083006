import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class Process extends Component {

  render() {
    const { subtitle, title, content, process } = this.props

    return (
      <>
        <section className='process full-section'>
          <div className='process__inner'>
            <span className='subtitle'>{subtitle}</span>
            <h2 dangerouslySetInnerHTML={{ __html: title }} />
            <div dangerouslySetInnerHTML={{ __html: content }} />
            <div className='process__items'>
              { process.map((el, i) => (
                <div className='process__item' key={i}>
                  { el.icon && <img src={el.icon?.localFile?.childImageSharp.original.src} alt={el.label} /> }
                  <h4>{el.label}</h4>
                  <div dangerouslySetInnerHTML={{ __html: el.content }} />
                </div>
              )) }
            </div>
          </div>
        </section>
      </>
    )
  }
}

export default Process
